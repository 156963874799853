//@ts-nocheck
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useCheckLogin = () => {
  const { beAdminUrl } = window._env_;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const checkLogin = async () => {
      const res = await fetch(`${beAdminUrl}/bearer`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        navigate(pathname === "/login" ? "/" : pathname, { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
    };
    checkLogin();
  }, [beAdminUrl, navigate, pathname]);
};

export default useCheckLogin;
